import Head from 'next/head';
import React, { FunctionComponent } from 'react';
import generateMetaTags from '../../components/layout/MetaTags';
import PedenKnowles from '../../components/mortgages/PedenKnowles';

const metaData = {
  title: 'PropertyPal Mortgages',
  description:
    'Find The Best Mortgage Deals In Northern Ireland With PropertyPal Mortgages. Find Out How Much You Could Borrow.',
  url: `/mortgages`,
};

const Mortgages: FunctionComponent = () => {
  return (
    <>
      <Head>{generateMetaTags(metaData)}</Head>

      <PedenKnowles />
    </>
  );
};

export default Mortgages;
