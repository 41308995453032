import { laptopSmallMax, tabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const BLUE = '#61a4d7';

export const Banner = styled.div`
  background-color: ${BLUE};
  padding: 15px;
  margin: 45px auto;
  max-width: 1280px;

  p {
    color: ${({ theme }) => theme.white};
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }

  @media (${tabletMax}) {
    margin: 15px 0;
    padding: 10px;

    p {
      font-size: 14px;
    }
  }
`;

export const TitleBox = styled.div`
  h1 {
    font-size: 40px;
    max-width: 950px;
    margin: 0 auto;
    text-align: center;
    color: ${({ theme }) => theme.textDark};
    font-weight: normal;
  }

  svg {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 450px;
  }

  @media (${tabletMax}) {
    h1 {
      font-size: 28px;
    }
  }
`;

export const InfoBox = styled.div`
  background-color: ${({ theme }) => theme.backgroundLighter};
  padding: 60px 0;

  & > div {
    width: 100%;
    max-width: 820px;
    padding: 0 15px;
    margin: 0 auto;
  }

  h2 {
    color: ${({ theme }) => theme.textDark};
    text-align: center;
    font-size: 36px;

    span {
      color: ${BLUE};
    }
  }

  @media (${tabletMax}) {
    h2 {
      font-size: 28px;
    }
  }
`;

export const CheckRow = styled.div`
  display: flex;
  gap: 15px;
  margin: 30px 0;

  svg {
    margin-top: 3px;
  }

  p {
    color: ${({ theme }) => theme.textDark};
    font-size: 24px;
  }

  @media (${tabletMax}) {
    p {
      font-size: 18px;
    }
  }
`;

export const ContactBox = styled.div`
  padding: 80px 0 100px;

  h3 {
    text-align: center;
    font-size: 36px;
    color: ${({ theme }) => theme.textDark};
  }

  p {
    text-align: center;
    font-size: 24px;
    color: ${({ theme }) => theme.textDark};
    margin: 30px auto 60px;
    max-width: 540px;

    b {
      display: inline-block;
    }
  }

  @media (${laptopSmallMax}) {
    padding: 60px 15px;

    h3 {
      font-size: 28px;
    }

    p {
      margin: 30px auto;
      font-size: 20px;
    }
  }
`;

export const ContactRow = styled.div`
  display: flex;
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  padding: 0 15px;
  gap: 15px;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  a {
    text-align: center;
    color: ${({ theme }) => theme.textDark};
    font-size: 24px;
    margin-top: 15px;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (${laptopSmallMax}) {
    flex-direction: column;
    gap: 30px;

    a {
      font-size: 20px;
    }
  }
`;
