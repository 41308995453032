import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faLaptop } from '@fortawesome/pro-regular-svg-icons/faLaptop';
import { faPhone } from '@fortawesome/pro-regular-svg-icons/faPhone';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import PedenKnowlesLogo from '@propertypal/shared/src/resources/logos/PedenKnowles.svg';
import FontAwesomeIcon from '@propertypal/web-ui/src/icons/FontAwesomeIcon';
import PageWrapper from '@propertypal/web-ui/src/layout/PageWrapper';
import React from 'react';
import { Banner, BLUE, CheckRow, ContactBox, ContactRow, InfoBox, TitleBox } from './PedenKnowles.style';

const PedenKnowles = () => {
  return (
    <>
      <PageWrapper>
        <Banner>
          <p>PropertyPal has no affiliation with, or ownership interest in, any mortgage broker.</p>
        </Banner>

        <TitleBox>
          <h1>
            <b>PropertyPal Mortgages</b> is now <b>Peden Knowles Financial.</b>
          </h1>

          <PedenKnowlesLogo />
        </TitleBox>
      </PageWrapper>

      <InfoBox>
        <div>
          <h2>
            Existing clients of PropertyPal Mortgages: <span>What This Means for You</span>
          </h2>

          <CheckRow>
            <FontAwesomeIcon icon={faCheck} color={BLUE} size={30} />
            <p>
              <b>Same Great Service:</b> You can expect the same high level of service and expertise you have come to
              rely on.
            </p>
          </CheckRow>

          <CheckRow>
            <FontAwesomeIcon icon={faCheck} color={BLUE} size={30} />
            <p>
              <b>New Brand, Same Team:</b> Our dedicated team members will continue to support you under the Peden
              Knowles Financial name.
            </p>
          </CheckRow>

          <CheckRow>
            <FontAwesomeIcon icon={faCheck} color={BLUE} size={30} />
            <p>
              <b>Seamless Transition:</b> There will be no disruption to your current mortgage services. All existing
              agreements and terms will remain unchanged.
            </p>
          </CheckRow>
        </div>
      </InfoBox>

      <ContactBox>
        <h3>Contact Information</h3>

        <p>
          Moving forward, please direct all enquiries and communications to Peden Knowles Financial.{' '}
          <b>You can reach them at:</b>
        </p>

        <ContactRow>
          <div>
            <FontAwesomeIcon icon={faEnvelope} color={BLUE} size={38} />
            <a href="mailto:info@pedenknowles.com">info@pedenknowles.com</a>
          </div>

          <div>
            <FontAwesomeIcon icon={faPhone} color={BLUE} size={38} />
            <a href="tel:02890026999">028 90 026 999</a>
          </div>

          <div>
            <FontAwesomeIcon icon={faLaptop} color={BLUE} size={38} />
            <a href="https://www.pedenknowles.com/">www.pedenknowles.com</a>
          </div>
        </ContactRow>
      </ContactBox>
    </>
  );
};

export default PedenKnowles;
